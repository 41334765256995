import PropTypes from 'prop-types';
import React, { Component } from 'react';

import {
  ROUTES,
  changeRoute,
  redirectToMarketplace,
  redirectToKnowledgeBase,
} from '~/routing-utils.js';
import { getVerificationDetails, login } from '~/requests';

import Login from './Login';

export default class LoginContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tosContent: null,
      loggedIn: false,
      verified: false,
      acceptedTOS: false,
      loginValidationMessage: null,
      disableLoginSubmit: false,
    };
  }

  onLoginSubmit = async (username, password, useKnowledgeBase = false) => {
    const urlParams = new URLSearchParams(window.location.search);
    const redirectUrl = urlParams.get('RedirectPath') || '';

    const loginStatus = await login(username, password, useKnowledgeBase, redirectUrl);

    // Locked
    if (loginStatus.accountLocked) {
      changeRoute(this.props.history, ROUTES.ACCOUNT_LOCKED);
      return;
    }

    // Failed login, not locked
    if (!loginStatus.loginSuccessful) {
      this.setState({
        loginValidationMessage: 'Your username and password didn’t match. Please try again.',
      });
      return;
    }

    const verificationStatus = await getVerificationDetails(this.props.history.location.search);

    // Not verified
    if (!verificationStatus.verified) {
      changeRoute(this.props.history, ROUTES.VERIFY);
      return;
    }

    // Verified, but needs to accept TOS
    if (!verificationStatus.acceptedTOS) {
      changeRoute(this.props.history, ROUTES.TOS);
      return;
    }

    if (loginStatus.statusCode === 302) {
      redirectToKnowledgeBase(loginStatus.redirectUrl);
      return;
    }

    // Success!
    redirectToMarketplace();
    return;
  };

  render() {
    return (
      <Login
        loginValidationMessage={this.state.loginValidationMessage}
        onLoginSubmit={async (username, password, useKnowledgeBase) => {
          if (this.state && this.state.disableLoginSubmit === true) {
            return;
          }

          this.setState({ disableLoginSubmit: true });
          await this.onLoginSubmit(username, password, useKnowledgeBase);
          this.setState({ disableLoginSubmit: false });
        }}
      />
    );
  }
}
LoginContainer.propTypes = {
  history: PropTypes.object.isRequired,
};
